





























































































































































































import { Component, Mixins, Vue } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import actuacionModule from "@/store/modules/actuacion-module";
import { actuacion } from "@/shared/dtos/actuacion";
import enumeracionModule from "@/store/modules/enumeracion-module";
import { enumeracion } from "@/shared/dtos/enumeracion";
import usuarioModule from "@/store/modules/usuario-module";
import departamentoModule from "@/store/modules/departamento-module";
import ambitoModule from "@/store/modules/ambito-module";
import { Cabecera_Enumeraciones_FormularioDto } from "@/shared/dtos/Cabecera_Enumeraciones_FormularioDto";
import { DynamicChangeEventClass } from "@/components/DinamicallyForm/DynamicChangeEventClass";
import { API } from "@/shared/api";
import objetivoModule from "@/store/modules/objetivo-module";
import { UtilsDate } from "@/utils/utils-date";
import { MessageService } from "@/shared/services/message-service";
import kpis_actuacionModule from "@/store/modules/kpis_actuacion-module";
import { kpis_actuacion } from "@/shared/dtos/kpis_actuacion";
import { FormDateDiff } from "@/shared/dtos/FormDateDiff";

@Component({
  components: {
    FullDynamicTable: () => import("../dynamic_tables/full_dynamic_table.vue"),
    DocumentsHandler: () => import("../Documentos/DocumentsHandler.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
    DynamicTable: () => import("../dynamic_tables/dynamic_table.vue"),
    KpisFormulario: () => import("@/views/actuaciones/kpisFormulario.vue"),
    KpisMediciones: () => import("@/views/actuaciones/kpisMediciones.vue"),
  },
})
export default class actuacionesFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: actuacion = new actuacion();
  public tipos_actuacion: enumeracion[] = [];
  public prioridades: enumeracion[] = [];
  public show_dialog: boolean = false;
  public show_dialog_kpis: boolean = false;
  public show_dialog_mediciones_kpis: boolean = false;
  public id_kpis: number = 0;

  public async OnCreated() {
    this.item_pantalla.fecha_inicio = new Date();
    this.item_pantalla.fecha_fin = new Date();
    this.item_pantalla.fecha_inicio_ejecucion = new Date();
    this.item_pantalla.fecha_fin_ejecucion = new Date();

    if (this.getparamId().HayParametro) {
      await actuacionModule.getactuacion(this.getparamId().Parametro);
      this.item_pantalla = actuacionModule.actuacion;
      await kpis_actuacionModule.getkpis_actuaciones();
      this.item_pantalla.kpis = kpis_actuacionModule.kpis_actuaciones.map(
        (x) => x
      );
    }

    await enumeracionModule
      .getenumeraciones_fromtipo(1)
      .then((x: Cabecera_Enumeraciones_FormularioDto) => {
        this.tipos_actuacion = x.enumeraciones;
      });
    await enumeracionModule
      .getenumeraciones_fromtipo(2)
      .then((x: Cabecera_Enumeraciones_FormularioDto) => {
        this.prioridades = x.enumeraciones;
      });
    await usuarioModule.getusuarios();
    await ambitoModule.getambitos();
    await actuacionModule.getactuaciones();
    await objetivoModule.getobjetivos();
    await departamentoModule.getdepartamentos();

    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
  }

  public CreateNameTabs() {
    this.AddTabName("Ficha");
    this.AddTabName("Actuaciones");
    this.AddTabName("Tareas");
    this.AddTabName("KPIS");
    this.AddTabName("Documentos");
  }

  ver_mediciones(id_kpis: kpis_actuacion) {
    this.show_dialog_mediciones_kpis = true;
    this.id_kpis = id_kpis.id;
  }
  kpis_list(x: any) {
    //kpis_actuacionModule.getkpis_actuaciones();
    try {
      var ids = this.item_pantalla.kpis.map((x) => x.id);
      kpis_actuacionModule.getkpis_actuaciones();
    } catch (error) {}

    return kpis_actuacionModule.kpis_actuaciones.filter(
      (x) => x.id_actuacion == this.item_pantalla.id
    );
  }

  refrescar_kpis(x: any) {
    this.item_pantalla.kpis.push(x);
    //@ts-ignore
    this.$refs["data_tablekpis"].refresh();
  }

  add_nuevo_kpi() {}

  add_nueva_vinculacion() {
    this.show_dialog = true;
  }

  actuaciones_list(x: any) {
    var ids = this.item_pantalla.actuaciones.map((x) => x.id);
    ids.push(this.item_pantalla.id);//&& x.id_actuacion == 0
    return actuacionModule.actuaciones.filter(
      (x) => !ids.includes(x.id) 
    );
  }

  add_actuaciones_list(x: actuacion) {
    this.item_pantalla.actuaciones.push(x);
    x.id_actuacion = this.item_pantalla.id;
    actuacionModule
      .modificaractuacion(x)
      .then(() => (this.show_dialog = false));
  }
  eliminar_vinculacion(actuacion: actuacion) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar las vinculación ?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          var ids = this.item_pantalla.actuaciones.map((x) => {
            if (x.id !== actuacion.id) {
              return x.id;
            }
          });
          var actuaciones = actuacionModule.actuaciones.filter((x) =>
            ids.includes(x.id)
          );

          this.item_pantalla.actuaciones.splice(
            0,
            this.item_pantalla.actuaciones.length
          );

          for (let index = 0; index < actuaciones.length; index++) {
            console.log(actuaciones[index]);
            this.item_pantalla.actuaciones.push(actuaciones[index]);
          }

          actuacion.id_actuacion = 0;
          actuacionModule.modificaractuacion(actuacion);
        }
      }
    );
  }

  public get_url() {
    if (this.getparamId().HayParametro) {
      return {
        url: API.actuacion + "/documents/" + this.getparamId().Parametro,
        url_download: API.webApiBaseactuaciones + this.getparamId().Parametro,
      };
    } else {
      return {
        url: "",
        url_download: "",
      };
    }
  }
  Recalcular_FechaPrev(e: DynamicChangeEventClass) {
    var fecha = UtilsDate.AddDias(
      new Date(this.GetDataItem("fecha_inicio")),
      this.GetDataItem("plazo_estimado")
    );

    this.SetFormItem("fecha_fin").data = fecha.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    this.Recalcular_Desviacion_plazos();
  }

  Recalcular_FechaEje(e: DynamicChangeEventClass) {
    var fecha = UtilsDate.AddDias(
      new Date(this.GetDataItem("fecha_inicio_ejecucion")),
      this.GetDataItem("plazo_ejecucion")
    );

    this.SetFormItem("fecha_fin_ejecucion").data = fecha.toLocaleDateString(
      "es-ES",
      {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }
    );
    this.Recalcular_Desviacion_plazos();
  }
  Recalcular_Desviacion_plazos() {
    this.SetFormItem("desvicion_plazos").data = parseFloat(
      (
        this.GetDataItem("plazo_estimado") - this.GetDataItem("plazo_ejecucion")
      ).toFixed(2)
    );
    /*
    var diffInMs =
      new Date(this.GetDataItem("fecha_fin")).getTime() -
      new Date(this.GetDataItem("fecha_fin_ejecucion")).getTime();

    this.SetFormItem("desvicion_fecha").data = Math.floor(
      diffInMs / (1000 * 60 * 60 * 24)
    );*/
    var datos_fechas: FormDateDiff = new FormDateDiff();
    datos_fechas.DateTime1 = this.GetDataItem("fecha_fin");
    datos_fechas.DateTime2 = this.GetDataItem("fecha_fin_ejecucion");
    actuacionModule
      .FormDateDiff(datos_fechas)
      .then((x) => (this.SetFormItem("desvicion_fecha").data = x));
  }

  Recalcular_Presupuesto(e: DynamicChangeEventClass) {
    /* this.SetFormItem("desvicion_presupuesto_data").data =
      this.GetDataItem("inversion_prevista") -
      this.GetDataItem("inversion_realizada");*/
    this.SetFormItem("desvicion_presupuesto_data").data = parseFloat(
      (
        this.GetDataItem("inversion_prevista") -
        this.GetDataItem("inversion_realizada")
      ).toFixed(2)
    );
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica
    this.AddFormItemDataDefaultSlot(
      undefined,
      "DATOS GENERALES",
      "sl_datos_generales",
      20
    ).Dimesiones(12, 12, 12);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.codigo,
      "Código",
      "codigo"
    ).isRequired();

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre"
    );

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_responsable,
      "Responsable",
      "id_responsable"
    )
      .isRequired()
      .CreateComboSingle(usuarioModule.usuarios, "nombre", "id");

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_objetivo,
      "Objetivo",
      "id_objetivo"
    )
      .CreateComboSingle(objetivoModule.objetivos, "nombre", "id")
      .Dimesiones(12, 3, 3)
      .isRequired();

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_ambito,
      "Ámbito  / Sub-Ámbito ",
      "id_ambito"
    )
      .CreateComboSingle(ambitoModule.ambitos, "nombre", "id")
      .Dimesiones(12, 3, 3);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_prioridad,
      "Prioridad",
      "id_prioridad"
    )
      .CreateComboSingle(this.prioridades, "nombre", "id")
      .Dimesiones(12, 3, 3);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_departamento,
      "Departamento",
      "id_departamento"
    )
      .CreateComboSingle(departamentoModule.departamentos, "nombre", "id")
      .Dimesiones(12, 3, 3)
      .isRequired();

    this.AddFormItemDataDefaultString(
      this.item_pantalla.descripcion,
      "Descripción",
      "descripcion"
    ).CreateTextArea();

    /*Campos de planificacion prevista*/

    this.AddFormItemDataDefaultSlot(
      undefined,
      "PLANIFICACIÓN PREVISTA",
      "sl_planificacion",
      20
    ).Dimesiones(12, 12, 12);

    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.fecha_inicio),
      "Inicio",
      "fecha_inicio"
    ).isReadOnlySoloSeleccionFecha();

    var plazo_estimado = this.AddFormItemDataDefaultNumber(
      this.item_pantalla.plazo_estimado,
      "Duración",
      "plazo_estimado"
    );
    plazo_estimado.vbind = { suffix: "Días" };

    /* this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.fecha_fin),
      "Fin",
      "fecha_fin"
    ).isReadOnly();*/

    var fecha = new Date(this.item_pantalla.fecha_fin);

    this.AddFormItemDataDefaultString(
      fecha.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      "Fin [Autocalculado]",
      "fecha_fin"
    ).isReadOnly();

    var inversion_prevista = this.AddFormItemDataDefaultNumber(
      this.item_pantalla.inversion_prevista,
      "Presupuesto",
      "inversion_prevista"
    ).CrearValidacion("decimal:2");
    inversion_prevista.vbind = { suffix: "€" };

    /*Apartado Ejecuccion*/
    this.AddFormItemDataDefaultSlot(
      undefined,
      "EJECUCIÓN",
      "sl_ejecucion",
      20
    ).Dimesiones(12, 12, 12);

    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.fecha_inicio_ejecucion),
      "Inicio ejecución",
      "fecha_inicio_ejecucion"
    ).isReadOnlySoloSeleccionFecha();
    
    var plazo_ejecucion = this.AddFormItemDataDefaultNumber(
      this.item_pantalla.plazo_ejecucion,
      "Duracion",
      "plazo_ejecucion"
    );
    plazo_ejecucion.vbind = { suffix: "Días" };

    /* this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.fecha_fin),
      "Fin",
      "fecha_fin"
    ).isReadOnly();*/

    var fecha_ejecucion = new Date(this.item_pantalla.fecha_fin_ejecucion);

    this.AddFormItemDataDefaultString(
      fecha_ejecucion.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      "Fin [Autocalculado]",
      "fecha_fin_ejecucion"
    ).isReadOnly();

    var inversion_realizada = this.AddFormItemDataDefaultNumber(
      this.item_pantalla.inversion_realizada,
      "Importe adjudicación",
      "inversion_realizada"
    ).CrearValidacion("decimal:2");
    inversion_realizada.vbind = { suffix: "€" };

    /*Apartado Calculos

Desviación fecha fin
Desviación duración
Desviación presupuesto

Peso en el objetivo
Ejecución sobre total

*/

    this.AddFormItemDataDefaultSlot(
      undefined,
      "CÁLCULOS",
      "sl_calculos",
      20
    ).Dimesiones(12, 12, 12);

    console.log(this.item_pantalla.tareas);

    /*Primero calculo el % total  de las tareas*/
    var porcen_total = 0;
    this.item_pantalla.ejecucion_sobre_total = 0;
    if (this.item_pantalla.tareas != undefined) {
      for (let index = 0; index < this.item_pantalla.tareas.length; index++) {
        const element = this.item_pantalla.tareas[index];
        //porcen_ejecucion = % de la actuacion
        porcen_total = porcen_total + element.porcen_ejecucion;
      }
      // una vez tenemos todo contabilidado hay que hazelo sobre 100
      for (let index = 0; index < this.item_pantalla.tareas.length; index++) {
        const element = this.item_pantalla.tareas[index];
        var porcen_real = (element.porcen_ejecucion * 100) / porcen_total;
        this.item_pantalla.ejecucion_sobre_total =
          this.item_pantalla.ejecucion_sobre_total +
          (element.porcen_trabajo_total / 100) * porcen_real;
      }
    }

    this.item_pantalla.ejecucion_sobre_total = Math.round(
      this.item_pantalla.ejecucion_sobre_total
    );

    var ejecucion_sobre_total = this.AddFormItemDataDefaultNumber(
      this.item_pantalla.ejecucion_sobre_total,
      "Ejecución sobre total",
      "ejecucion_sobre_total"
    ).isReadOnly();
    ejecucion_sobre_total.vbind = { suffix: "%" };

    var desvicion_presupuesto_data = parseFloat(
      (
        this.item_pantalla.inversion_prevista -
        this.item_pantalla.inversion_realizada
      ).toFixed(2)
    );

    var desvicion_presupuesto = this.AddFormItemDataDefaultString(
      desvicion_presupuesto_data,
      "Desviación presupuesto",
      "desvicion_presupuesto_data"
    ).isReadOnly();
    desvicion_presupuesto.vbind = { suffix: "€" };

    var desvicion_plazos_data =
      this.item_pantalla.plazo_estimado - this.item_pantalla.plazo_ejecucion;
    var desvicion_plazos = this.AddFormItemDataDefaultString(
      desvicion_plazos_data,
      "Desviación de duración",
      "desvicion_plazos"
    ).isReadOnly();
    desvicion_plazos.vbind = { suffix: "Días" };

    var diffInMs =
      new Date(this.item_pantalla.fecha_fin).getTime() -
      new Date(this.item_pantalla.fecha_fin_ejecucion).getTime();
    var desvicion_fecha_fin_data = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    var desvicion_fecha_fin = this.AddFormItemDataDefaultString(
      desvicion_fecha_fin_data,
      "Desviación fecha fin",
      "desvicion_fecha"
    ).isReadOnly();
    desvicion_fecha_fin.vbind = { suffix: "Días" };

    /*Otras pestañas*/
    if (!this.EstoyCreado) {
      this.AddFormItemDataDefaultSlot(
        this.item_pantalla.actuaciones,
        "Actuaciones",
        "actuaciones",
        500,
        2
      ).Dimesiones(12, 12, 12);

      this.AddFormItemDataDefaultSlot(
        this.item_pantalla.tareas,
        "Tareas",
        "tareas",
        500,
        3
      ).Dimesiones(12, 12, 12);
      this.AddFormItemDataDefaultSlot(
        this.item_pantalla.kpis,
        "KPIS",
        "kpis",
        500,
        4
      ).Dimesiones(12, 12, 12);
      this.AddFormItemDataDefaultSlot(
        null,
        "Documentos",
        "documentos",
        500,
        5
      ).Dimesiones(12, 12, 12);
    }
  }

  abrir_formulario_tareas(id: string) {
    this.$router.push({
      name: "TareasFormulario",
      params: { id: id },
      query: { actuacion: this.item_pantalla.id },
    });
  }
  public Insertar(object: actuacion) {
    var dateString = object.fecha_fin.toString();
    var parts = dateString.split("/");
    var year = parseInt(parts[2], 10);
    var month = parseInt(parts[1], 10) - 1;
    var day = parseInt(parts[0], 10);
    object.fecha_fin = new Date(year, month, day);

        dateString = object.fecha_fin_ejecucion.toString();
    parts = dateString.split("/");
    year = parseInt(parts[2], 10);
    month = parseInt(parts[1], 10) - 1;
    day = parseInt(parts[0], 10);
    object.fecha_fin_ejecucion = new Date(year, month, day);


    actuacionModule.guardaractuacion(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: actuacion) {
    /*var dateString = object.fecha_fin.toString();
    var parts = dateString.split("/");
    var year = parts[2];
    var month = parts[1].padStart(2, "0");
    var day = parts[0].padStart(2, "0");
    object.fecha_fin = `${year}/${month}/${day}`;*/

    var dateString = object.fecha_fin.toString();
    var parts = dateString.split("/");
    var year = parseInt(parts[2], 10);
    var month = parseInt(parts[1], 10) - 1;
    var day = parseInt(parts[0], 10);
    object.fecha_fin = new Date(year, month, day);

    dateString = object.fecha_fin_ejecucion.toString();
    parts = dateString.split("/");
    year = parseInt(parts[2], 10);
    month = parseInt(parts[1], 10) - 1;
    day = parseInt(parts[0], 10);
    object.fecha_fin_ejecucion = new Date(year, month, day);

    actuacionModule.modificaractuacion(object).then(() => {
      this.AllSaveOk();
    });
  }
}
