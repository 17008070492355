var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DinamicallyForm',{ref:"Formulario",attrs:{"FieldsForView":_vm.FieldsForRow,"FormReadOnly":_vm.FormReadOnly,"BotonGuardar":_vm.BotonGuardar,"BotonCancelar":_vm.BotonCancelar,"BotonClonar":_vm.BotonClonar,"AutoSaveData":_vm.AutoSaveData,"textos_tabs":_vm.textos_tabs},on:{"update:FieldsForView":function($event){_vm.FieldsForRow=$event},"update:fields-for-view":function($event){_vm.FieldsForRow=$event},"EventGuardar":function($event){return _vm.HandlerSave($event)},"EventCancelar":function($event){return _vm.Cancelar()},"EventClonar":function($event){return _vm.Clonar($event)},"EventChange":function($event){return _vm.EventChange($event)},"EventDontSave":function($event){return _vm.DontSave()},"fecha_inicio":function($event){return _vm.Recalcular_FechaPrev($event)},"plazo_estimado":function($event){return _vm.Recalcular_FechaPrev($event)},"fecha_inicio_ejecucion":function($event){return _vm.Recalcular_FechaEje($event)},"plazo_ejecucion":function($event){return _vm.Recalcular_FechaEje($event)},"inversion_prevista":function($event){return _vm.Recalcular_Presupuesto($event)},"inversion_realizada":function($event){return _vm.Recalcular_Presupuesto($event)}},scopedSlots:_vm._u([{key:"actuaciones",fn:function(ref){
var field = ref.field;
return [_c('FullDynamicTable',{ref:"data_table",attrs:{"id_tabla":"20","EventMethod":function () {
            return field.data;
          }},scopedSlots:_vm._u([{key:"before_search",fn:function(ref){return [_c('Xbtn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.add_nueva_vinculacion()}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Vincular nueva actuación ")]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("add")])],1)]}},{key:"before_acciones",fn:function(ref){
          var field = ref.field;
return [_c('Xbtn',{attrs:{"small":"","icon":"","color":"red","permisos":''},on:{"click":function($event){return _vm.eliminar_vinculacion(field)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Eliminar vinculación ")]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)})]}},{key:"sl_datos_generales",fn:function(ref){
          var field = ref.field;
return [_c('div',{staticClass:"mb-3"},[_c('div',{staticStyle:{"text-align":"left"}},[_c('i',{staticClass:"fas fa-arrow-right"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(field.caption))])]),_c('v-divider')],1)]}},{key:"sl_planificacion",fn:function(ref){
          var field = ref.field;
return [_c('div',{staticClass:"mb-3"},[_c('div',{staticStyle:{"text-align":"left"}},[_c('i',{staticClass:"fas fa-arrow-right"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(field.caption))])]),_c('v-divider')],1)]}},{key:"sl_ejecucion",fn:function(ref){
          var field = ref.field;
return [_c('div',{staticClass:"mb-3"},[_c('div',{staticStyle:{"text-align":"left"}},[_c('i',{staticClass:"fas fa-arrow-right"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(field.caption))])]),_c('v-divider')],1)]}},{key:"sl_calculos",fn:function(ref){
          var field = ref.field;
return [_c('div',{staticClass:"mb-3"},[_c('div',{staticStyle:{"text-align":"left"}},[_c('i',{staticClass:"fas fa-arrow-right"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(field.caption))])]),_c('v-divider')],1)]}},{key:"tareas",fn:function(ref){
          var field = ref.field;
return [_c('FullDynamicTable',{attrs:{"id_tabla":"21","EventMethod":function () {
            return field.data;
          }},on:{"new":function($event){return _vm.abrir_formulario_tareas($event)},"edit":function($event){return _vm.abrir_formulario_tareas($event)}}})]}},{key:"documentos",fn:function(ref){
          var field = ref.field;
return [_c('DocumentsHandler',{attrs:{"url":_vm.get_url().url,"url_download":_vm.get_url().url_download,"EventMethod":function () {
            return field.data;
          }}})]}},{key:"kpis",fn:function(ref){return [_c('FullDynamicTable',{ref:"data_tablekpis",attrs:{"id_tabla":"25","EventMethod":_vm.kpis_list},on:{"new":function($event){{
            _vm.show_dialog_kpis = true;
            _vm.id_kpis = 0;
          }},"edit":function ($event) {
            _vm.show_dialog_kpis = true;
            _vm.id_kpis = $event;
          }},scopedSlots:_vm._u([{key:"before_acciones",fn:function(ref){
          var field = ref.field;
return [_c('Xbtn',{attrs:{"small":"","icon":"","color":"green","permisos":''},on:{"click":function($event){return _vm.ver_mediciones(field)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Mediciones ")]},proxy:true}],null,true)},[_c('i',{staticClass:"fad fa-table fa-lg"})])]}}],null,true)})]}}])}),(_vm.show_dialog_kpis)?_c('KpisFormulario',{attrs:{"show":_vm.show_dialog_kpis,"id":_vm.id_kpis},on:{"update:show":function($event){_vm.show_dialog_kpis=$event},"update:id":function($event){_vm.id_kpis=$event},"refrescar":function($event){return _vm.refrescar_kpis($event)}}}):_vm._e(),(_vm.show_dialog_mediciones_kpis)?_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.show_dialog_mediciones_kpis),callback:function ($$v) {_vm.show_dialog_mediciones_kpis=$$v},expression:"show_dialog_mediciones_kpis"}},[(_vm.show_dialog_mediciones_kpis)?_c('KpisMediciones',{attrs:{"id":_vm.id_kpis},on:{"update:id":function($event){_vm.id_kpis=$event},"refrescar":function($event){return _vm.refrescar_kpis($event)}}}):_vm._e()],1):_vm._e(),(_vm.show_dialog)?_c('v-dialog',{model:{value:(_vm.show_dialog),callback:function ($$v) {_vm.show_dialog=$$v},expression:"show_dialog"}},[_c('DynamicTable',{attrs:{"id_tabla":"24","EventMethod":_vm.actuaciones_list},on:{"edit":function($event){return _vm.add_actuaciones_list($event)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }